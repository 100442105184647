import { useMemo } from "react";
import useWindowSize from "./useWindowSize";

export const DOTS = "...";

const range = (start: number, end: number) => {
  const length = end - start + 1;
  return Array.from({ length }, (_, idx) => idx + start);
};
type PaginationType = {
  totalPosts: number;
  postsPerPage: number;
  siblingCount: number;
  currentPage: number;
};
export const usePagination = ({
  totalPosts,
  postsPerPage,
  siblingCount = 1,
  currentPage,
}: PaginationType) => {
  const { width } = useWindowSize();

  const defaultSibling = width > 441 ? 5 : 3;
  const triggerPoint = width > 441 ? 2 : 1;

  const paginationRange = useMemo(() => {
    const totalPageCount = Math.ceil(totalPosts / postsPerPage);

    // Pages count is determined as siblingCount + firstPage + lastPage + currentPage + 2*DOTS
    const totalPageNumbers = siblingCount + defaultSibling;

    /*
      If the number of pages is less than the page numbers we want to show in our
      paginationComponent, we return the range [1..totalPageCount]
    */
    if (totalPageNumbers >= totalPageCount) {
      return range(1, totalPageCount);
    }

    const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
    const rightSiblingIndex = Math.min(
      currentPage + siblingCount,
      totalPageCount
    );

    /*
      We do not want to show dots if there is only one position left 
      after/before the left/right page count as that would lead to a change if our Pagination
      component size which we do not want
    */
    const shouldShowLeftDots = leftSiblingIndex > triggerPoint;
    const shouldShowRightDots =
      rightSiblingIndex < totalPageCount - triggerPoint;

    const firstPageIndex = 1;
    const lastPageIndex = totalPageCount;

    if (!shouldShowLeftDots && shouldShowRightDots) {
      const leftItemCount = defaultSibling * siblingCount;
      const leftRange = range(1, leftItemCount);

      return [...leftRange, DOTS, totalPageCount];
    }

    if (shouldShowLeftDots && !shouldShowRightDots) {
      const rightItemCount = defaultSibling * siblingCount;
      const rightRange = range(
        totalPageCount - rightItemCount + 1,
        totalPageCount
      );
      return [firstPageIndex, DOTS, ...rightRange];
    }

    if (shouldShowLeftDots && shouldShowRightDots) {
      const middleRange = range(leftSiblingIndex, rightSiblingIndex);
      return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex];
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    totalPosts,
    postsPerPage,
    siblingCount,
    currentPage,
    defaultSibling,
    triggerPoint,
  ]);

  return paginationRange;
};
