import { Pagenation } from "./Pagenation";
import { LoadMoreButton } from "./LoadMore";
import { AnimatePresence, animate, motion } from "framer-motion";
import { opacity_variant } from "../../utils/transition";
import { PaginationPageType } from "../../utils/baseTypes";

interface LoadMorePaginationProp {
  postsPerPage: number;
  postsTotal: number;
  currentPage: number;
  pageType: PaginationPageType;
  hasPostsToLoad: boolean;
  extraClass?: string;
}

export const LoadMorePagination = ({
  currentPage,
  pageType,
  postsPerPage,
  postsTotal,
  hasPostsToLoad,
  extraClass = "",
}: LoadMorePaginationProp) => {
  return (
    <div className={`column is-12 loadmore-pagination ${extraClass}`}>
      <div className="columns is-multiline">
        <div className="column is-12-mobile is-4-tablet btn-wrapper">
          <AnimatePresence>
            {hasPostsToLoad && (
              <motion.div
                variants={opacity_variant}
                initial={"initial"}
                animate={"animate"}
                exit={"exit"}
              >
                <LoadMoreButton pageType={pageType} page={currentPage + 1} />
              </motion.div>
            )}
          </AnimatePresence>
        </div>
        <div className="column is-12-mobile is-8-tablet pagination-column p-0">
          <Pagenation
            postsPerPage={postsPerPage}
            postsTotal={postsTotal}
            currentPage={currentPage}
            pageType={pageType}
          />
        </div>
      </div>
    </div>
  );
};
