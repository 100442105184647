export function findNumbersBetweenEllipsis(arr: (number | string)[]): number[] {
  const ellipsisIndices: number[] = [];

  arr.forEach((item, index) => {
    if (item === "...") {
      ellipsisIndices.push(index);
    }
  });

  if (ellipsisIndices.length !== 2) {
    // Handle cases where there are zero, one, or more than two ellipsis in the array
    return [];
  }

  const startIndex: number = ellipsisIndices[0];
  const endIndex: number = ellipsisIndices[1];

  if (startIndex >= endIndex - 1) {
    // Handle cases where the indices are not in the correct order or adjacent

    return [];
  }

  const numbersBetween: number[] = arr
    .slice(startIndex + 1, endIndex)
    .filter((item): item is number => typeof item === "number");

  return numbersBetween;
}
